<template>
    <swipe-out class="ml-n1 pl-1" threshold="30">
        <template #left v-if="isOnAndroid">
            <div class="swipe-actions swipeout-action align-self-center" style="max-width: 110px;">
                <cost-list-actions :item="item" v-on="$listeners"/>
            </div>
        </template>
        <v-row no-gutters v-touch="{start: () => tapStarted(), end: () => tapEnded(), move: () => moving()}">
            <v-col cols="auto" class="align-self-center mr-2" v-if="!isOnAndroid">
                <v-simple-checkbox :value="isSelected(item)" @input="select(item, $event)" v-ripple/>
            </v-col>
            <v-col cols="auto" class="align-self-center mr-4">
                <v-fade-transition leave-absolute>
                    <v-badge overlap color="transparent" offset-x="10" offset-y="10" v-if="!selectedOnAndroid">
                        <template #badge v-if="item.imported">
                            <v-icon x-small color="primary">$curve</v-icon>
                        </template>
                        <name-with-icon :value="{icon: item.categoryIcon, color: item.categoryColor}" hide-name
                                        large/>
                    </v-badge>
                    <v-btn color="primary" dark fab small elevation="0" v-else style="width: 36px; height: 36px;">
                        <v-icon large>$selected</v-icon>
                    </v-btn>
                </v-fade-transition>
            </v-col>
            <v-col>
                <v-row no-gutters ref="descriptionLine">
                    <v-col class="text-subtitle-2" cols="5">
                        <div>{{ item.categoryName }}
                            <v-icon :color="item.sourcePocketColor">
                                {{ item.sourcePocketIcon }}
                            </v-icon>
                        </div>
                        <div v-if="isMobile">{{ item.date | asHunDate }}</div>
                    </v-col>
                    <v-col v-if="!isMobile" class="auto">
                        <v-row no-gutters class="justify-end text-subtitle-2">
                            <div>{{ item.date | asHunDate }}</div>
                        </v-row>
                    </v-col>
                    <v-col class="align-self-end text-h6" v-if="isMobile">
                        <v-row no-gutters class="justify-end mr-3">
                            <cost-value :value="{cost: formattedCost, unexpected: item.unexpected}"/>
                        </v-row>
                    </v-col>
                    <v-col cols="12" style="min-height: 22px;">
                        <div class="text-caption text-truncate" style="max-width: 250px;">
                            {{ item.description ? item.description : '' }}
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="align-self-center text-h6" v-if="!isMobile">
                <v-row no-gutters class="justify-end">
                    <cost-value :value="{cost: formattedCost, unexpected: item.unexpected}"/>
                </v-row>
            </v-col>
            <v-col cols="auto align-self-center" v-if="!isOnAndroid">
                <cost-list-actions :item="item" small v-on="$listeners" class="ml-3"/>
            </v-col>
        </v-row>
    </swipe-out>
</template>

<script>
import {toCurrency} from "@/utils/CurrencyField/CurrencyUtils";
import {toHunDate} from "@/utils/DatePicker/DateUtils";
import PlatformInfoMixin from "@/utils/platform/PlatformInfoMixin";

import {SwipeOut} from 'vue-swipe-actions';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import CostListActions from "@/cost/components/CostListActions";
import CostValue from "@/cost/components/CostValue";
import {DEFAULT_COLOR} from "@/category/colors";
import NameWithIcon from "@/utils/icons/NameWithIcon";

export default {
    name: "cost-list-item",
    mixins: [PlatformInfoMixin],
    components: {
        NameWithIcon,
        CostValue,
        CostListActions,
        SwipeOut
    },
    props: {
        item: Object,
        index: Number,
        select: Function,
        isSelected: Function
    },

    data() {
        return {
            longPressTimer: null
        };
    },

    computed: {
        selectedOnAndroid() {
            return this.isSelected(this.item) && this.isOnAndroid;
        },
        formattedCost() {
            return toCurrency(this.item.cost);
        },
        categoryColor() {
            return this.item.categoryColor ?? DEFAULT_COLOR;
        }
    },

    methods: {
        tapStarted() {
            this.longPressTimer = setTimeout(() => this.longPressed(), 500);
        },
        tapEnded() {
            clearTimeout(this.longPressTimer);
            this.longPressTimer = null;
        },
        moving() {
            clearTimeout(this.longPressTimer);
        },
        longPressed() {
            if (this.isOnAndroid) {
                this.select(this.item, !this.isSelected(this.item));
            }
        }
    },

    filters: {
        asHunDate: value => toHunDate(value)
    }
};
</script>

<style scoped>
.swipe-actions {
    margin-right: 14px;
    border-right-style: solid;
    border-color: lightgrey;
}
</style>
